import style from './MysteriousElixir.module.scss';
import React, {useEffect, useState} from "react";
import flower from '../assets/flower.svg';
import footer from '../assets/footer.svg';
import slide_01 from '../assets/01.jpg';
import slide_02 from '../assets/02.jpg';
import slide_03 from '../assets/03.jpg';
import slide_04 from '../assets/04.jpg';
import slide_05 from '../assets/05.jpg';
import slide_06 from '../assets/06.jpg';
// import arrow from '../assets/arrow.svg';
import {useSwipeable} from "react-swipeable";
import {useCookies} from "react-cookie";
import StyleHelper from "../../../services/Style.helper";

const MYSTERIOUS_ELIXIR = "PASSEPORT";
const MYSTERIOUS_ELIXIR_COOKIE = "MysteriousElixirIsConnected";

const MysteriousElixir: React.FC = () => {
    const [cookies, setCookie, removeCookie] = useCookies([`${MYSTERIOUS_ELIXIR_COOKIE}`]);
    const [password, setPassword] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>("");
    const [isConnected, setIsConnected] = useState<boolean>(() => {
        return !!cookies[`${MYSTERIOUS_ELIXIR_COOKIE}`];
    });
    const [currentIndex, setCurrentIndex] = useState<number>(0);

    const images: string[] = [
        slide_01,
        slide_02,
        slide_03,
        slide_04,
        slide_05,
        slide_06,
    ];

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (password.toUpperCase() !== MYSTERIOUS_ELIXIR) {
            console.log('password', password);
            setErrorMessage("Le mot de passe n’est pas le bon ! Essaye encore !");
            removeCookie(`${MYSTERIOUS_ELIXIR_COOKIE}`);
        } else {
            setErrorMessage("");
            setIsConnected(true);
            setCookie(`${MYSTERIOUS_ELIXIR_COOKIE}`, true);
        }
    };

    const handleSwipe = (direction: string) => {
        if (direction === 'LEFT') {
            setCurrentIndex(getNextIndex(currentIndex));
        } else if (direction === 'RIGHT') {
            setCurrentIndex(getPreviousIndex(currentIndex));
        }
    };

    const handlers = useSwipeable({
        onSwipedLeft: () => handleSwipe('LEFT'),
        onSwipedRight: () => handleSwipe('RIGHT'),
        delta: 10, // Ajustez la sensibilité du glissement
        swipeDuration: 500, // Durée de l'animation de glissement en millisecondes
        trackMouse: true
    });

    useEffect(() => {
        setErrorMessage("");
    }, [password]);

    const getNextIndex = (index: number): number => {
        if (index === images.length - 1) {
            return index;
        }

        return index + 1;
        // return (index + 1) % images.length;
    }

    const getPreviousIndex = (index: number): number => {
        if (index === 0) {
            return index;
        }

        return index - 1;
    }

    function disconnecting() {
        removeCookie(`${MYSTERIOUS_ELIXIR_COOKIE}`);
        setIsConnected(false);
    }

    const getClassNames = (index: number, currentIndex: number): string => {
        if (index === currentIndex) return style.active;
        if (index <= getPreviousIndex(currentIndex)) return style.previous;
        if (index >= getNextIndex(currentIndex)) return style.next;
        return '';
    };

    return (
        <div className={style.body}>
            <div className={style.header}>LE MYSTÈRE DE L'ELIXIR</div>
            <div className={style.container}>
                {
                    isConnected ? (
                        <div>
                            <button className={style.logout} onClick={disconnecting}>Se déconnecter</button>
                            <h1>Passeport de voyage de Bobby Mirage</h1>
                            <div className={style.slider} {...handlers}>
                                {
                                    images.map((image, index) => (
                                        <img key={index}
                                             src={image}
                                             alt={`Slide ${index + 1}`}
                                             className={StyleHelper.concatClass([
                                                 style.image,
                                                 getClassNames(index, currentIndex),
                                             ])}/>
                                    ))
                                }
                            </div>
                            <span className={style.counter}>{`${currentIndex + 1} / ${images.length}`}</span>
                            {/*<div className={style.arrowBox}>*/}
                            {/*    <img className={style.left} onClick={() => handleSwipe('RIGHT')} src={arrow}*/}
                            {/*         alt="Previous"/>*/}
                            {/*    <img className={style.right} onClick={() => handleSwipe('LEFT')} src={arrow}*/}
                            {/*         alt="Next"/>*/}
                            {/*</div>*/}
                        </div>
                    ) : (
                        <form>
                        <h1>Espace personnel de Bobby Mirage</h1>
                            <p>Pour accéder à l’historique de vos voyage veuillez vous connecter.</p>
                            <div className={style.loginBox}>
                                <img className={style.flower}
                                     alt="Illustration of a flower with a yellow center and brown outline"
                                     src={flower}/>
                                <h2>Connectez-vous</h2>
                                <label htmlFor="password">Mot de passe</label>
                                <input className={style.input} id="password" name="password" type="text"
                                       onChange={(e: React.ChangeEvent<HTMLInputElement>) => setPassword(e.target.value)}/>
                                <button onClick={handleSubmit}>Valider</button>
                            </div>
                            <div className={style.errorMessage}>{errorMessage}</div>
                        </form>
                    )
                }
            </div>
            <img alt="Illustration of a flower with a yellow center and brown outline"
                 className={style.bottomFlowers}
                 src={footer}/>
        </div>
    )
};

export default MysteriousElixir;
